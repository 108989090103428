//------------------------
// Header Component
//------------------------

// PACKAGES
import React from "react"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { graphql, useStaticQuery } from "gatsby"

// COMPONENTS
import Logo from "../../../assets/logos/ie-base-code.svg"
import MenuArrow from "../../../assets/icons/menu-arrow.svg"

// COMPONENT BUILD
const Navbar = () => {
    // DATA QUERY
    const data = useStaticQuery(graphql`
        query {
            markdownRemark(frontmatter: {type: {eq: "landing"}}) {
                frontmatter {
                    alert {
                        alert_text
                    }
                }
            }
        }
    `)
    
    // JSX TEMPLATE
    return (
            <header>
                <nav className="px-4 bg-white navbar fixed-top navbar-expand-md navbar-light border-bottom border-ie-lighter">
                <div className="container">
                    <div className="px-0 col-3 col-xl-4">
                        <img className="logo-size" src={Logo} alt="Informeasy"/>
                    </div>
                    <div className="px-0 early-bird-banner-xl col-5 col-sm-7 col-md-5 col-xl-4 alig-content-center justify-content-center">
                        <div className="mr-3 bg mr-md-4">
                        <div className="shake">
                            <div className="element">
                            <svg width="31" height="32" viewBox="0 0 31 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.3678 5.76594V3.60756H17.9798C18.4448 3.60756 18.8478 3.23756 18.8478 2.74421C18.8478 2.25087 18.4758 1.88086 17.9798 1.88086H13.0508C12.5858 1.88086 12.1828 2.25087 12.1828 2.74421C12.1828 3.23756 12.5548 3.60756 13.0508 3.60756H14.6318V5.76594C8.09082 6.19762 2.91382 11.6244 2.91382 18.2229C2.91382 25.1297 8.55582 30.7415 15.4688 30.7415C22.3818 30.7415 28.0238 25.1297 28.0238 18.2229C28.0548 11.6244 22.8778 6.19762 16.3678 5.76594ZM15.4998 29.0456C9.51682 29.0456 4.64982 24.2047 4.64982 18.2229C4.64982 16.7737 4.95982 15.2936 5.54882 13.9678C6.10682 12.6728 6.94382 11.4702 7.96682 10.4835C8.98982 9.49686 10.2298 8.69517 11.5318 8.17099C12.1518 7.92432 12.8028 7.73932 13.4538 7.61598C15.0968 7.30764 16.8328 7.36931 18.4448 7.83182C19.8088 8.23266 21.1108 8.88018 22.2268 9.77436C23.3428 10.6685 24.2728 11.7477 24.9548 12.9811C25.6678 14.2453 26.1018 15.6637 26.2568 17.082C26.2878 17.452 26.3188 17.822 26.3188 18.192C26.3498 24.1738 21.4828 29.0456 15.4998 29.0456Z" fill="#444444"/>
                                <path d="M21.8857 18.0996H16.3677V11.6553C16.3677 11.1928 15.9957 10.792 15.4997 10.792C15.0037 10.792 14.6317 11.162 14.6317 11.6553V18.0996H13.3607C12.8957 18.0996 12.4927 18.4697 12.4927 18.963C12.4927 19.4563 12.8647 19.8264 13.3607 19.8264H14.6317V21.0905C14.6317 21.5531 15.0037 21.9539 15.4997 21.9539C15.9957 21.9539 16.3677 21.5839 16.3677 21.0905V19.8264H21.8857C22.3507 19.8264 22.7537 19.4563 22.7537 18.963C22.7537 18.4697 22.3507 18.0996 21.8857 18.0996Z" fill="#444444"/>
                            </svg>
                            </div>
                        </div>
                        </div>
                    <p className="my-auto mb-0 text-left text-center early-bird-banner-xl-text early-bird-banner-text text-ie-black">
                    {data.markdownRemark.frontmatter.alert.alert_text}
                    </p>
                    </div>
                    <button
                        className="px-2 py-2 border-0 navbar-toggler"
                        type="button"
                        data-toggle="collapse"
                        data-target="#navigations-08"
                        aria-controls="navigations-08"
                        aria-expanded="false"
                        aria-label="Toggle menu"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div
                        className="collapse navbar-collapse"
                        id="navigations-08"
                    >
                        <ul className="ml-auto navbar-nav">
                            <li className="nav-item">
                                <AnchorLink offset="0" className="pt-6 nav-link menu-link mr-lg-5 py-md-3" href="#preview">
                                <img style={{top: "4px"}} className="mr-3 d-inline-block d-md-none position-relative" src={MenuArrow} alt="Arrow-icon"/>
                                    Preview
                                </AnchorLink>
                            </li>
                            <li className="nav-item">
                                <AnchorLink offset="105" className="py-3 nav-link menu-link mr-lg-5 py-md-3" href="#pricing">
                                <img style={{top: "4px"}} className="mr-3 d-inline-block d-md-none position-relative" src={MenuArrow} alt="Arow-icon"/>
                                    Pricing
                                </AnchorLink>
                            </li>
                            <li className="nav-item">
                                <AnchorLink offset="10" className="pb-6 nav-link menu-link py-md-3" href="#faq">
                                <img style={{top: "4px"}} className="mr-3 d-inline-block d-md-none position-relative" src={MenuArrow} alt="Arrow-icon"/>    
                                    FAQ
                                </AnchorLink>
                            </li>
                        </ul>
                    </div>
                    </div>
                </nav>
            </header>
    )
}

export default Navbar
